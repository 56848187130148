import { AppLang } from '@/lang/_template.type';

const es: AppLang = {
	app_name: 'Pixcap',
	signup_banner: {
		title: '¡Regístrate y <span class="text-weight-600">Obtén un {number}% de DESCUENTO</span> en Todo!',
		cta_title: 'Regístrate ahora',
	},
	auth_wall: {
		title: 'Inicia sesión o Regístrate para continuar navegando',
	},
	toast_messages: {
		logout: 'Has cerrado sesión exitosamente',
		session_change: 'Tu sesión ha sido terminada debido a un nuevo inicio de sesión desde otra ubicación.',
		upload_image_failed: 'Error al subir la imagen. Por favor, inténtalo de nuevo.',
		subscription: {
			upgraded: {
				title: 'Suscripción mejorada',
				text: '¡Muchas gracias por apoyar a Pixcap!',
			},
			cancelled: {
				title: 'Suscripción cancelada',
				text: 'Tu suscripción Pixcap {subscription_type} ha sido cancelada.',
			},
			expired: {
				title: 'Suscripción expirada',
				text: 'Tu suscripción Pixcap ha expirado. Por favor, elige un plan para renovar.',
			},
			paused: {
				title: 'Suscripción pausada',
				text: 'Hubo un problema con el pago de tu suscripción Pixcap. Por favor, reintenta el pago para seguir disfrutando de tu suscripción {subscription_type}.',
			},
		},
		user_settings: {
			notification_updated: '¡Las notificaciones fueron actualizadas!',
			failed: 'Error al actualizar tus ajustes, por favor intenta de nuevo',
		},
	},

	upgrade_modal: {
		more: 'más',
		unlimited: 'ilimitado',
		billing_cycle: 'Ciclo de facturación',
		payment_secure: 'Pago asegurado con Stripe',
		one_month: '1 mes',
		twelve_month: '12 meses',
		continue_to_payment: 'Continuar al pago',
		per_month: 'por mes',
		billed_annually: 'facturado anualmente',
		title: {
			download_glb: `Descarga GLB con <br> <span class="text-color-primary-5">Pixcap {plan}</span> ✨`,
			get_more_ai_usage: `Obtén {count_prefix} generaciones de IA con <span class="text-color-primary-5">Pixcap {plan}</span> ✨`,
			get_more_hq_video_download: `Obtén más descargas de video <br> que se ajusten a tus necesidades.`,
			access_premium_project: `Edita tus diseños premium <br> con <span class="text-color-primary-5">Pixcap {plan}</span>`,
			remove_watermark: `Elimina la marca de agua con <br> <span class="text-color-primary-5">Pixcap {plan}</span> ✨`,
			hit_usage_limit_ai: `Límite mensual alcanzado ({number}). <br> Actualiza a <span class="text-color-primary-5">Pixcap {plan}</span> ✨ <br> para {count_prefix} generaciones de IA.`,
			hit_usage_limit_default_download: `Límite diario alcanzado ({number}). <br> Actualiza a <span class="text-color-primary-5">Pixcap {plan}</span> ✨ <br> para descargas ilimitadas.`,
			hit_usage_limit_hq_image_download: `Límite mensual alcanzado ({number}). <br> Actualiza a <span class="text-color-primary-5">Pixcap {plan}</span> ✨ <br> para {count_prefix} descargas.`,
			pro_to_elite: `Actualiza tu cuenta <br> a <span class="text-color-primary-5">Pixcap Elite</span> ✨`,
			use_pro_item: `Actualiza tu cuenta <br> a <span class="text-color-primary-5">Pixcap {plan}</span> ✨`,
		},
		tooltip: {
			elite_feature: 'Esta función no está disponible para PRO',
			current_plan: 'Este es tu plan actual',
		},
		features: {
			unlimited: 'Ilimitado',
			access_premium_designs: 'Acceso a todos los diseños PREMIUM',
			default_quality_downloads: '{number} descargas en calidad estándar',
			hq_image_downloads: '{number} imágenes en alta calidad por mes',
			hq_video_downloads: '{number} videos en alta calidad por mes',
			ai_usage: '{number} generaciones de IA por mes',
			exclusive_materials: 'Presets y materiales exclusivos',
			exclusive_character_poses: 'Poses de personaje exclusivas',
			commercial_license: 'Licencia comercial',
			file_3d_export: 'Exportaciones de archivos 3D',
		},
	},
	payment_status_modal: {
		title: {
			topped_up: 'Has recargado {topup_quantity} límites de video',
			updated_subscription: `¡Ahora eres {subscription_type}!`,
			subscribed_to: `¡Te has suscrito a {subscription_type}!`,
		},
		description: {
			buy_item: 'Puede tomar unos momentos para que se actualicen los artículos comprados',
			topped_up: `Puede tomar unos momentos para que se añadan los {topup_quantity} límites de video recargados`,
			updated_subscription: `¡Vamos a revolucionar tu mundo creativo! 🚀`,
			subscribed_to: `Puede tomar unos momentos para que se actualice la suscripción`,
		},
		cta: {
			confirm: `¡Vamos!`,
			manage_subscription: 'Gestionar suscripción',
		},
	},
	promo_banner: {
		title:
			'Disfruta de un {discount}% de descuento en los planes Anuales Pro y Elite con el código promocional: <span class="text-weight-600">{promo_code}!</span>',
		cta_text: 'Obtén {discount}% de Descuento',
	},
	navbar: {
		search_placeholder: 'Buscar...',
		search_dropdown: {
			recent_search: 'Búsqueda reciente',
			popular_search: 'Búsquedas populares',
			suggested_search: 'Búsquedas sugeridas',
		},
		account: 'Cuenta',
		navigation_items: {
			browse: 'Explorar',
			asset: 'Activos 3D',
			asset_dropdown: {
				icons_title: 'Íconos 3D',
				icons_description: '+15,000 íconos e ilustraciones 3D gratuitos y premium',
				characters_title: 'Personajes 3D',
				characters_description: '+1,000 personajes 3D gratuitos y premium',
				mockups_title: 'Maquetas',
				mockups_description: '+500 maquetas y kits de maquetas de marca gratuitos y premium',
				all_assets_title: 'Activos 3D',
				explore_all: 'Explorar todo',
			},
			tools: 'Herramientas',
			tools_dropdown: {
				prefix: 'Mejores herramientas de IA',
				ai_3d_model: 'Generador de modelos 3D de IA',
				ai_character: 'Generador de personajes de IA',
				ai_girl: 'Generador de chicas de IA',
				ai_background: 'Generador de fondos de IA',
				ai_filter: 'Filtro de IA',
				ai_icon: 'Generador de íconos de IA',
				icon_maker: 'Creador de íconos 3D',
				animated_icon: 'Icono Animado',
				character_creator: 'Creador de Personajes 3D',
				figma_plugin: 'Plugin de Figma',
			},
			pricing: 'Precios',
		},
		language_switcher: {
			tooltip: 'Idioma',
		},
		my_projects: 'Mis Proyectos',
		get_unlimited_download: 'Obtén Descargas Ilimitadas',
		login: 'Login',
		sign_up: 'Registrarse',
		notification_dropdown: {
			notifications: 'Notificaciones',
			empty: 'No tienes ninguna notificación',
		},
		download_dropdown: {
			full_download_history: 'Historial completo de descargas',
			tooltip: {
				download_history: 'Historial de descargas',
				download_completed: 'Descarga completada',
			},
			show_all_downloads: 'Mostrar todas las descargas',
			empty: 'Está vacío aquí. \n Intenta Descargar (Alta Calidad) \n para ver dónde ocurre la magia.',
			upgrade: {
				enjoy: '¿Sabías que los usuarios Pro/Elite disfrutan de <span class="text-color-pro">renderizados 3 veces más rápidos?</span>',
				text: '¡Actualiza ahora para aumentar la velocidad de descarga de alta calidad!',
				upgrade_now: 'Actualizar ahora',
			},
			cancel_download_modal: {
				cancel_download: 'Cancelar descarga de alta calidad',
				text: 'Perderás el saldo utilizado para este video. ¿Estás seguro de que quieres continuar?',
				text_gif: 'Perderás el saldo utilizado para este GIF. ¿Estás seguro de que quieres continuar?',
				confirm: 'Confirmar',
			},
		},
		user_dropdown: {
			projects: 'Proyectos',
			downloads: 'Descargas',
			account_settings: 'Configuración de la cuenta',
			invite_friends: 'Invitar amigos',
			contact_us: 'Contáctanos',
			shared_project: 'Proyectos compartidos',
			contributor_files: 'Archivos de colaborador',
			sign_out: 'Cerrar sesión',
		},
		login_form: {
			title: 'Desbloquea más de 15,000 activos 3D personalizables',
			description: 'Iconos animados 3D de alta calidad, personajes 3D y maquetas.',
			google_login: 'Continuar con Google',
			create_account: 'Crear una cuenta',
			create_account_heading: 'Estás creando una cuenta de Pixcap con',
			next: 'Próximo',
			back: 'Volver',
			verify: 'Verificar',
			accept_term: 'Al continuar, aceptas nuestros',
			email_login: {
				email: 'Ingresa el correo',
				password: 'Introducir contraseña',
				confirm_password: 'Confirmar nueva contraseña',
				resend: 'Reenviar',
				email_login: 'Continuar con correo electrónico',
				enter_verification_code: 'Ingrese el código de verificación',
				verification_sent: 'Hemos enviado un código de verificación a',
				verify_email: 'Verificar correo electrónico',
				enter_email: 'Ingresa dirección de correo electrónico',
				enter_email_message: `Te iniciaremos sesión, o crearemos una cuenta<br />si aún no tienes una.`,
				check_email:
					'Revisa tu correo electrónico en <span class="text-color-primary-5">{{ email }}</span> para obtener un código para verificar tu correo.',
				invalid_code: 'El código ingresado no es válido, intenta de nuevo',
				did_not_received: '¿No recibiste el correo electrónico?',
				error_email: 'El correo electrónico no es válido',
				error_E02_02_001: 'El correo electrónico o la contraseña son incorrectos.',
				error_E02_02_002: 'Este correo electrónico ya está en uso',
				error_E02_02_003: 'Algo salió mal, por favor intente de nuevo',
				error_default: 'No se pudo iniciar sesión, por favor intente de nuevo',
			},
		},
		forgot_pw_form: {
			forgot_pw: '¿Olvidaste tu contraseña?',
			sub: 'Por favor, ingresa tu dirección de correo electrónico para restablecer tu contraseña.',
			check_your_email: 'Le enviamos un correo electrónico a <strong>{email}</strong> con un código de restablecimiento de contraseña.',
			sub_text: 'Te hemos enviado un correo electrónico con un enlace para restablecer tu contraseña.',
			did_not_received: '¿No recibiste el correo electrónico?',
			error_email: 'El correo electrónico no es válido',
			enter_reset_code: 'Introduzca el código de reinicio',
			reset_password: 'Restablecer su contraseña',
			change_password: 'Cambiar la contraseña',
			continue: 'Continuar',
		},

		contact_us_form: {
			contact_us: 'Contáctanos',
			name: 'Nombre',
			enter_your_name: 'Ingresa tu nombre',
			email: 'Correo electrónico',
			enter_your_email: 'Ingresa tu correo electrónico',
			message: 'Tu mensaje',
			write_message: 'Escribe tu mensaje aquí',
			send: 'Enviar mensaje',
			error_name: '**El nombre no puede estar vacío',
			error_email: '**El correo electrónico no es válido',
		},
	},
	sidebar: {
		home: 'Inicio',
		library: 'Biblioteca',
		my_projects: 'Mis Proyectos',
		my_assets: 'Mis Activos',
		pricing: 'Precios',
		unlock_all_features: 'Desbloquear todas las funciones',
		upgrade_plan: 'Mejorar plan',
	},
	breadcrumb: {
		home: 'Inicio',
		mockups: 'Maquetas',
		brandkit_mockups: 'Maquetas de Kit de Marca',
		icon_packs: 'Paquetes de Iconos 3D',
		illustrations: 'Ilustraciones',
		designs: 'Diseños',
	},
	footer: {
		title: 'Explora más contenido 3D',
		searched_title: 'Explorar búsquedas relevantes',
		seo_section: {
			sort_by: {
				latest: 'Más reciente',
				trending: 'Tendencia',
			},
		},
		footer_section: {
			explore: {
				title: 'Explorar',
			},
			features: {
				title: 'Características',
				animated_mockup: 'Maquetas animadas',
				ai_style_transfer: 'Transferencia de estilo de IA',
				character_library: 'Biblioteca de personajes',
				material_library: 'Biblioteca de materiales',
			},
			company: {
				title: 'Empresa',
				pricing: 'Precios',
				enterprise: 'Empresa',
				privacy_policy: 'Política de privacidad',
				terms_of_service: 'Términos y Condiciones',
				contact_us: 'Contáctanos',
			},
			resources: {
				title: 'Recursos',
				glossary: 'Glosario',
				contributor: 'Conviértete en colaborador',
			},
			tools: {
				title: 'Herramientas',
				ai_3d_model: 'Generador de modelos 3D de IA',
				ai_character: 'Generador de personajes de IA',
				ai_girl: 'Generador de chicas de IA',
				ai_background: 'Generador de fondos de IA',
			},
			create: {
				title: 'Crear',
				mug_design: 'Diseño de taza',
				sticker_design: 'Diseño de pegatinas',
				friendsgiving_invitation: 'Invitación de Friendsgiving',
				poster_mockup: 'Maqueta de póster',
				iphone_mockup: 'Maqueta de iPhone',
			},
			blogs: {
				title: 'Blogs',
				all_blogs: 'Todos los blogs',
			},
			follow_us: {
				title: 'Síguenos',
			},
		},
	},
	page: {
		home: {
			intro: {
				title: 'El Único Mercado para',
				title_highlighted: 'Recursos 3D Animados Editables',
				description: 'Edita maquetas animadas de alta calidad, iconos 3D, personajes 3D e ilustraciones 3D en tu navegador web.',
			},
			metrics: {
				metrics: 'Métricas',
				title: '¿Por Qué Incorporar Pixcap <br /> en Tu Flujo de Trabajo?',
				metric_content: {
					metric_1: {
						description: 'Aumento en las tasas de conversión al usar 3D en diseños',
					},
					metric_2: {
						title: '5 minutos',
						description: 'Para empezar a diseñar en 3D con Pixcap',
					},
					metric_3: {
						description: 'Aumento en las tasas de éxito de presentaciones al incluir maquetas 3D animadas',
					},
				},
				carousel: {
					title: 'Haz el Cambio al Branding 3D',
					title_highlighted: 'Sigue a los Líderes de la Industria y',
					content: {
						item_1: {
							text: '"Creemos que nuestro sistema de marca y cómo nos mostramos visualmente al mundo como Android 3D Robot debe reflejar el ethos central de Android de ser abierto, iterativo e inclusivo".',
							description: 'Android Robot, 2021',
						},
						item_2: {
							text: '"Samsung utiliza renderizaciones 3D para vender en sus planes de marketing y comercialización para entornos minoristas."',
							description: 'Renderizado 3D en tienda, 2018',
						},
						item_3: {
							text: '"El símbolo de Meta fue diseñado para el metaverso, permitiendo el movimiento a su alrededor. Se adapta con texturas, colores y movimiento infinitos, reflejando un mundo creativo en 3D".',
							description: 'Nueva Marca de la Compañía, 2021',
						},
						item_4: {
							text: '"La nueva forma 3D de Snoo abre la puerta al próximo acto del personaje. También sienta las bases para un nuevo estilo de ilustración central, pasando de ilustraciones puntuales sin un hilo común a icónicos íconos 3D heroicos".',
							description: 'Rediseño de marca, 2023',
						},
						item_5: {
							text: '"La década de 2010 estuvo dominada por el diseño plano de iOS 7, pero el diseño plano ya pasó. Estamos volviendo a un mundo con color, textura, dimensionalidad y más retroalimentación háptica".',
							description: 'Rediseño de Invierno de Airbnb, 2023',
						},
					},
				},
			},

			curated_mockup_list: {
				title: 'Crea Tus Propias Maquetas Realistas de Dispositivos y <br /> Branding',
				description: 'Las maquetas ahora son completamente personalizables - sube tu etiqueta a cualquier maqueta animada y descárgala en 4K.',
			},
			animated_pack_list: {
				title: 'Crea Fácilmente Elementos 3D Animados Únicos',
				description: 'Prueba nuestro sencillo editor para ajustar colores, materiales y estilos de animación de cualquier recurso 3D.',
			},
			title: 'El Único Mercado de <br> <span class="text-weight-600 text-color-primary-5">Recursos 3D Editables</span>',
			description: 'Edita iconos 3D, personajes 3D, maquetas e ilustraciones 3D de alta calidad en tu navegador web.',
			partners: {
				title: 'Utilizado por equipos de',
			},
			interactive_banner: {
				title: 'Crea Fácilmente Elementos 3D Únicos',
				description: 'Prueba nuestro editor simple para ajustar colores, materiales y estilos de animación de cualquier recurso 3D.',
				default: 'Predeterminado',
				color: 'Color',
			},
			animated_icon_pack: 'Iconos 3D Animados',
			animated_icon_pack_description: 'Personaliza iconos 3D animados cambiando colores, rotando la cámara, ajustando materiales y más.',
			icon_pack: 'Iconos 3D',
			icon_pack_description: 'Personaliza iconos 3D cambiando colores, rotando la cámara, ajustando materiales y más.',
			characters_pack_description: 'Crea personajes e ilustraciones 3D únicos cambiando poses y colores.',
			device_mockup_pack_description: 'Sube tu etiqueta y crea maquetas UX/UI únicas. Personaliza colores y descarga en MP4, PNG o GIF.',
			branding_mockup_pack_description: 'Sube tu etiqueta y crea maquetas de marca únicas. Personaliza colores y descarga en MP4, PNG o GIF.',
			latest_icon_pack: 'Último paquete de íconos 3D',
			characters_pack: 'Personajes 3D',
			device_mockup_pack: 'Maquetas de dispositivos',
			branding_mockup_pack: 'Maquetas de marca',

			advertise: {
				title: '¡Paga Una Vez, Usa 3D de Múltiples Formas, <br/> Todo el Año!',
				description: 'Edita y personaliza modelos 3D animados y estáticos, personajes y maquetas para todas las industrias y casos de uso.',
				first_card: {
					title: 'Sitio Web y Móvil',
					description:
						'Usa elementos 3D para mejorar el diseño de tus proyectos. Capta la atención de tus usuarios y destaca tu sitio web y aplicación de la competencia.',
				},
				second_card: {
					title: 'Maquetas animadas',
					description: 'Carga tu diseño en cualquier maqueta animada, edita el fondo a tu gusto y descárgalo en 4K en menos de 5 minutos.',
				},
				third_card: {
					title: 'Presentación',
					description:
						'Aprovecha los personajes 3D y los íconos 3D para presentaciones. Haz que tu presentación de negocios o educativa sea más atractiva y dinámica.',
				},
			},
			compatible: {
				title: 'Compatible con Tu Software Favorito',
				description: 'Descarga contenido de Pixcap en cualquier tipo de archivo: GLB, MP4, GIF, PNG para usar con todos tus programas favoritos.',
			},

			banner: {
				title_desktop: `¡Comienza personalizando <br> cualquier contenido 3D!`,
				title_mobile: `¡Empieza a editar cualquier <br> contenido 3D!`,
				button_text: 'Explorar ahora',
			},
			header1: '¡Todas las cosas 3D son personalizables - Prueba ahora!',
			trending_icons: 'Iconos de tendencia',
			essential_icon: 'Paquetes de iconos esenciales',
			essential_illustrations: 'Kits de ilustración esenciales',
			essential_mockups: 'Maquetas realistas de dispositivos y marcas',
			header2: 'Explora más creativos 3D editables',
			fintech_illustrations: 'Ilustraciones de fintech',
			ecommerce_illustrations: 'Ilustraciones de comercio electrónico',
			healthcare_illustrations: 'Ilustraciones de salud',
			browse_all_illustrations: 'Explorar todas las ilustraciones',
			business_characters: 'Personajes de negocios',
			casual_characters: 'Personajes casuales',
			profession_characters: 'Personajes profesionales',
			browse_all_characters: 'Explorar todos los personajes',
			laptop_mockups: 'Maquetas de portátiles',
			smartphone_mockups: 'Maquetas de smartphones',
			branding_mockups: 'Maquetas de marca',
			browse_all_mockups: 'Explorar todas las maquetas',
		},
		library: {
			banner: {
				title_assets: 'El Único Mercado para <span class="text-color-primary-5 text-weight-600"><br />Recursos 3D Animados Editables</span>',
				title_icons: 'Personaliza y Edita Paquetes de Iconos 3D {animate} {tag}',
				title_characters: 'Edita y posa personajes 3D {tag}',
				title_mockups: 'Aplica diseños a maquetas {tag} de dispositivos y marca',
				description_assets: 'Edita maquetas animadas de alta calidad, iconos 3D, personajes 3D e ilustraciones 3D en tu navegador web.',
				description_icons:
					'Prueba nuestro Editor para ajustar colores, materiales y estilos de animación de tus iconos 3D {animate}. Descarga iconos 3D gratuitos en PNG, GLB, GIF, MP4 para usar en Figma, Sketch, Adobe y más.',
				description_characters:
					'Descubre la única herramienta de posado 3D que te permite posar tus personajes 3D, modificar colores y más, sin necesidad de experiencia en modelado 3D.',
				description_mockups:
					'Las maquetas ahora son completamente personalizables - sube tu etiqueta a cualquier maqueta animada y descarga en 4K en menos de 5 minutos.',
			},
			title: {
				prefix_customize: 'Personaliza',
				prefix_free: 'gratuitos',
				prefix_premium: 'premium',
				prefix_free_and_premium: 'gratuitos y premium',
				icons: 'paquetes de iconos 3D',
				characters: 'personajes 3D',
				mockups: 'maquetas',
			},
			description: {
				icons:
					'Elige entre {count} conjuntos de iconos de alta calidad para sitios web, aplicaciones, diseño gráfico y marketing. Personaliza y descarga iconos en PNG, GLB, GIF y MP4 para proyectos de diseño.',
				characters:
					'Aumenta el compromiso del usuario y la narración con ilustraciones de personajes. Personalízalos y úsalos en publicaciones de redes sociales, presentaciones, videos explicativos, demostraciones de productos y más.',
				mockups:
					'Mejora tus diseños y presentaciones con maquetas realistas. Fácilmente personalizables y perfectas para mostrar productos, marcas, empaques y más.',
			},
			header: '¡Explora contenido 3D totalmente personalizable!',
			cards: {
				icons: {
					icons: 'Iconos',
					browse_all: 'Explorar todos los iconos 3D',
				},
				characters: {
					characters: 'Personajes',
					browse_all: 'Explorar todos los personajes 3D',
				},
				illustrations: {
					illustrations: 'Ilustraciones',
					browse_all: 'Explorar todas las ilustraciones 3D',
				},
				mockups: {
					mockups: 'Maquetas',
					branding_kits: 'Kits de maquetas de marca',
					browse_all: 'Explorar todas las maquetas 3D realistas',
				},
				designs: {
					designs: 'Diseños',
					browse_all: 'Explorar todos los diseños 3D',
				},
			},
		},
		pack_details: {
			mockup_descriptions:
				'Inspire instantáneamente y muestre el diseño de su marca con nuestra biblioteca de plantillas profesionales de maquetas de marca. Desde elegantes tarjetas de visita y empaques llamativos hasta impresionantes recursos para redes sociales y maquetas de sitios web, tenemos todo lo que necesita para visualizar la identidad de su marca en un entorno realista.',
			features: {
				premium_asset: 'Todos los activos premium',
				exclusive_materials: 'Materiales y preajustes exclusivos',
				exclusive_poses: 'Poses de personajes exclusivas',
				commercial_license: 'Licencia comercial',
				export: 'Exportación de archivos 3D',
				edit: 'Editar colores, materiales y ajustes preestablecidos',
				downloads: 'Descargas de alta calidad',
				elite_unlimited_images: 'Imágenes de alta calidad ilimitadas',
				elite_video_per_month: '30 videos de alta calidad por mes',
			},
			get_all_access: 'Obtener acceso completo',
			info: {
				info: 'Información',
				number_of_items: 'Número de elementos',
				file_type: 'Tipo de archivo',
				compatible: 'Compatible con',
				release: 'Lanzamiento',
				author: 'Autor',
			},
			preview: {
				preview: 'Vista previa',
				default: {
					render: 'Renderiza usando el motor predeterminado del navegador',
					shadow: 'Sombras duras',
				},
				hq: {
					render: 'Renderiza usando el motor optimizado de nuestro servidor',
					shadow: 'Ray tracing, sombras y iluminación realistas',
				},
			},
			pack_description:
				'{pack_name} es un conjunto de íconos 3D e íconos animados perfectos para UX/UI, diseño gráfico y marketing. <br> El {pack_name} incluye una gran variedad de íconos para todas tus necesidades y mantiene un esquema de color coherente para un aspecto unificado. <br> Todos los íconos de {pack_name} están disponibles en PNG, GLB, GIF y MP4 para su uso en varias plataformas.',
			mockup_pack_description:
				'El paquete de maquetas {pack_name} es una colección de maquetas de alta calidad {pack_name} perfectas para mostrar tus diseños de una manera profesional. <br> El paquete de maquetas {pack_name} te permite personalizar y presentar tus diseños con facilidad. Simplemente carga tu diseño, edita el objeto y el fondo para crear una maqueta realista. <br> Todas las maquetas {pack_name} están disponibles en PNG, GIF y MP4 para su uso en varias plataformas.',
			character_pack_description:
				'El paquete de personajes {pack_name} es un conjunto de personajes 3D y personajes animados perfectos para sitios web, animación, diseño gráfico, marketing y presentaciones. <br> El paquete ofrece opciones de personalización para cada personaje, lo que le permite cambiar el tono de su piel, el color del cabello, la posición 3D y más. <br> Todos los personajes {pack_name} están disponibles en PNG, GLB, GIF y MP4 para su uso en varias plataformas.',
			browse_more: 'Explora más creativos 3D editables',
			related_to_this_pack: 'Relacionados con este paquete',
		},
		item_details: {
			features: {
				edit: 'Editar colores, materiales, iluminación...',
				edit_character: 'Editar poses, colores, materiales, iluminación...',
				edit_mockup: 'Reemplazar imágenes, editar colores, materiales, iluminación...',
				downloads: 'Descargas de alta calidad',
				export: 'Exportar archivos 3D',
				ai: 'Probar Transferencia de Estilo de IA',

				realistic: 'Maqueta 3D realista',
				editable: 'Diseño y fondo editables',
				easy: 'Herramientas fáciles de usar',
				high_quality: 'Exportación de alta calidad',
				free: 'Gratis para uso comercial',
				customize: 'Formas, colores y materiales personalizables',
				high_resolution: 'Diseño 3D de alta resolución',
				file_types: 'Tipos de archivo PNG, GLB',
				free_license: 'Licencia gratuita para proyectos comerciales y personales',
				ready_made: 'Plantillas listas y personalizables',
				easy_switch: 'Cambio fácil a varios tamaños',
				file_types_2: 'Tipo de archivo PNG',
				item_description:
					'{item_name} es el ícono perfecto para web, aplicaciones, UX/UI, diseño gráfico y marketing. <br> Tienes todas las capacidades de personalización con {item_name}, incluyendo cambiar colores, materiales y estilos para adaptarlos a tus proyectos de diseño. <br> Descarga {item_name} en PNG, GLB, GIF y MP4 y úsalos en Figma, Blender, Adobe y más.',
				character_description:
					'{item_name} es el personaje perfecto para sitios web, animación, diseño gráfico, marketing y presentaciones. <br> Tienes todas las capacidades de personalización con {item_name}, incluyendo cambiar el tono de piel, el color del cabello, la posición 3D y más. <br> Disponible en formatos de archivo PNG, GLB.',
				mockup_description:
					'Esta maqueta {item_name} es perfecta para mostrar tu marca, logo, diseño de sitio web y más. <br> Puedes personalizar fácilmente el objeto, el fondo y agregar tus propios elementos de diseño para hacerlo verdaderamente único. <br> La maqueta {item_name} viene en múltiples formatos de archivo como PNG, GIF y MP4 para una edición y uso sencillos en varias plataformas.',
			},
			edit_this_asset: 'Editar este activo',
			text_3d_graphic: 'Gráfico 3D de {item_name}',
			text_3d_illustration: 'Ilustración 3D de {item_name}',
			open_in_mnm: 'Abrir en Mix-n-match',
			more_from_this_pack: 'Más de este paquete',
			more_from_this_bundle: 'Plantillas en este paquete',
			people_also_downloaded: 'También descargaron',
			related: '{resouce_type} relacionados',
		},
		search: {
			explore: 'Explorar',
			prefix_free: 'gratis',
			prefix_premium: 'premium',
			prefix_free_and_premium: 'gratis y premium',
			icon_packs: 'paquetes de íconos',
			character_packs: 'paquetes de personajes',
			mockup_packs: 'paquetes de maquetas',
			asset_packs: 'paquetes de activos',
			icon: 'ícono',
			icons: 'íconos',
			character: 'personaje',
			characters: 'personajes',
			mockup: 'maqueta',
			mockups: 'maquetas',
			asset: 'activo',
			assets: 'activos',
			description: {
				suffix_animated: 'Disponible en formatos de archivo PNG, GLB, GIF, MP4.',
				suffix_pack: 'Prueba los paquetes de {search} {type} animados para captar la atención de los usuarios.',
				prefix_pack: 'Personaliza y descarga',
				suffix_icon_packs: 'paquetes para tus próximos proyectos de diseño.',
				suffix_character_packs: 'paquetes para web, aplicaciones, UX/UI, diseño gráfico y marketing.',
				suffix_mockup_packs: 'paquetes para presentaciones.',
				suffix_asset_packs: 'paquetes para tus próximos proyectos de diseño.',
				suffix_item: 'Prueba {search} {type} animados para captar la atención de los usuarios.',
				prefix_item: 'Personaliza y descarga',
				suffix_icon_items: 'íconos, ilustraciones, símbolos y logotipos para tus próximos proyectos de diseño.',
				suffix_character_items: 'para web, aplicaciones, UX/UI, diseño gráfico y marketing.',
				suffix_mockup_items: 'para tus próximos proyectos de diseño.',
				suffix_asset_items: 'para tus próximos proyectos de diseño.',
			},

			elements: 'elementos',
			packs: 'paquetes',
			illustrations: 'ilustraciones',
			designs: 'diseños',
			Elements: 'Elementos',
			Packs: 'Paquetes',
			Illustrations: 'Ilustraciones',
			Mockups: 'Maquetas',
			Designs: 'Diseños',
			empty: 'No te preocupes! Explora otros tipos de activos, o',
			request_new: 'solicita nuevo',
			cant_find: 'No puedes encontrar',
			browse_relevants: 'Explorar búsquedas relevantes',
		},
		my_projects: {
			title: 'Proyectos',
			description: 'Personaliza y administra fácilmente todos tus proyectos en un solo lugar.',
			header: 'Mis Proyectos',
			search_placeholder: 'Buscar diseño...',
			new_design: 'Nuevo Diseño',
			default: 'Predeterminado',
			advanced_editor: 'Editor Avanzado',
			all: 'Todos',
			design: 'Diseño',
			video: 'Video',
			edited: 'Editado',
			duplicate_loading: 'Duplicando proyecto....',
			sort: {
				sort_by: 'Ordenar por',
				recent: 'Reciente',
				alphabetical: 'Alfabético',
				oldest: 'Más antiguo',
			},
			menu: {
				open_in_advanced_editor: 'Abrir en Editor Avanzado',
				duplicate: 'Duplicar',
				duplicate_submission: 'Duplicar como envío',
				rename: 'Renombrar',
				delete: 'Eliminar',
			},
			empty_state: {
				title: '¡Vamos a llenar tus proyectos!',
				description: 'Esta página está vacía ahora. Es hora de empezar a personalizar activos 3D.',
			},
		},
		my_assets: {
			title: 'Descargas',
			description: 'Ve todos tus activos descargados aquí. Puedes volver a descargar tus activos en cualquier momento.',
			header: 'Mis Activos',
			search_placeholder: 'Buscar archivo',
			sort: {
				sort_by: 'Ordenar por',
				oldest: 'Más antiguo',
				newest: 'Más nuevo',
				alphabet_az: 'Alfabético (A-Z)',
			},
			type: {
				all: 'Todos los tipos',
				default_render: 'Renderizado predeterminado',
				high_quality: 'Alta calidad',
				ai_generated: 'Generado por IA',
			},
			downloads: {
				downloads: 'Descargas',
				file: 'Archivo',
				download_status: 'Estado de descarga',
				download_date: 'Fecha de descarga',
				empty_state: {
					title: '¡Vamos a llenar tus descargas!',
					description: 'Esta página está vacía ahora. Es hora de empezar a descargar activos geniales.',
					text: 'Tu página de Descargas se siente un poco solitaria ahora. Es un buen momento para empezar a exportar elementos geniales.',
					text_search: 'Intenta ajustar tu búsqueda o filtro para encontrar lo que estás buscando.',
					heading_1: 'Lo sentimos, no se encontraron resultados',
					heading_2: 'Lo sentimos, no pudimos encontrar coincidencias para “{search}“',
					button: 'Explorar más',
				},
			},
			purchases: {
				purchases: 'Compras',
				file: 'Archivo',
				purchase_date: 'Fecha de compra',
				open_file: 'Abrir archivo',
				empty_state: {
					title: 'Tu compra está vacía.',
					text: 'Llenemos este espacio con tus elementos.',
					text_search: 'Intenta ajustar tu búsqueda o filtro para encontrar lo que estás buscando.',
					heading_1: 'Lo sentimos, no se encontraron resultados',
					heading_2: 'Lo sentimos, no pudimos encontrar coincidencias para “{search}“',
					button: 'Explorar más',
				},
				order_no: 'Número de orden',
			},
		},
		upgrade_plan: {
			discount_text: '¡Disfruta de {discount_program}: {discount}% de descuento en tu plan!',
			end_in: 'La oferta por tiempo limitado termina en',
			header: 'Obtén Acceso Ilimitado a Miles de <span class="text-color-primary-5">Activos 3D Personalizables</span>',
			description:
				'Desbloquea el acceso a activos 3D estáticos y animados personalizables, personajes y maquetas. Paga una vez y úsalo durante todo el año - sin límites, sin restricciones.',
			show: 'Mostrar',
			is_pro:
				'¡Gracias por ser un suscriptor valioso! Estás disfrutando de nuestros antiguos precios más bajos hasta tu próxima renovación a la nueva tarifa.',
			monthly: 'Mensual',
			quarterly: '3 Meses',
			yearly: 'Anual',
			save_up: 'ahorra hasta un 33%',
			per_month: '/mes',
			recommended: 'Recomendado',
			current_short: 'Actual',
			current_plan: 'Plan actual',
			contact_sales: 'Contactar ventas',
			get_plan: 'Obtener {plan}',
			bill_annually: 'facturado {amount} anualmente',
			bill_quarterly: 'facturado {amount} por 3 meses',
			free: {
				free: 'Gratis',
				text: 'Texto',
				forever: 'Para siempre',
			},
			pro: {
				pro: 'Pro',
				text: 'Desbloquea funciones premium para proyectos más profesionales.',
			},
			elite: {
				elite: 'Elite',
				text: 'Para proyectos grandes con capacidades mejoradas.',
			},
			enterprise: {
				enterprise: 'Empresa',
				text: 'Soluciones avanzadas y soporte personalizado para proyectos de alto riesgo.',
				custom: 'Personalizado',
			},
			payment_partners: {
				text_1: 'Para tu seguridad, todos los pedidos se procesan a través de Stripe o PayPal.',
				text_2: 'Puedes cancelar tu suscripción en cualquier momento.',
			},
			benefits: {
				limited_free_assets: 'Activos gratuitos limitados',
				images_per_month: '{number} imágenes de alta calidad por mes',
				videos_per_month: '{number} vídeos de alta calidad por mes',
				exclusive_character_poses: 'Poses de personajes exclusivas',
				exclusive_materials_presets: 'Materiales y preajustes exclusivos',
				commercial_license: 'Licencia comercial',
				all_premiums: 'Todos los activos premium',
				export_3d_files: 'Exportación de archivos 3D',
				unlimited_images: 'Imágenes de alta calidad ilimitadas',
				figma_access: 'Acceso completo al plugin de Figma',
				upto_10_projects: 'Hasta 10 proyectos',
				monthly_credits_30: '30 créditos mensuales en Super Descargas y Pixcap AI',
				commercial_license_with_watermark: 'Licencia comercial (con marca de agua)',
				upto_50_projects: 'Hasta 50 proyectos',
				monthly_credits_600: '600 créditos mensuales en Super Descargas y Pixcap AI',
				super_download: 'Super Descargas 3 veces más rápidas (imagen)',
				unlimited_projects: 'Proyectos ilimitados',
				monthly_credits_2000: '2000 créditos mensuales en Super Descargas y Pixcap AI',
				dedicated_support: 'Soporte dedicado',
				everything_in_pro: 'Todo lo incluido en Pro',
				modeling_services: 'Servicios de modelado 3D',
				unlimited_credits: 'Créditos ilimitados',
				custom_animations: 'Animaciones personalizadas',
				custom_mockups: 'Maquetas personalizadas',
				everything_in_elite: 'Todo lo incluido en Elite',
			},
			vat_included: 'Impuesto al Valor Agregado (IVA) incluido',
			credit_cards: 'Aceptamos tarjetas de crédito y débito',
			cancel_anytime: 'Cancela en cualquier momento',
			trusted_by: 'De confianza para',
			show_full: 'Mostrar completo',
			hide: 'Ocultar',
			medium: 'Medio',
			fast: 'Rápido (3x)',
			limit_per_day: '{number} por día',
			limit_per_month: '{number} por mes',
			unlimited: 'Ilimitado',
			limited: 'Limitado',
			customization: {
				customization: 'Personalización',
				all_premiums: 'Todos los activos 3D premium (iconos, personajes y maquetas)',
				all_character_poses: 'Todas las poses de personajes',
				all_materials_presets: 'Todos los materiales y preajustes',
			},
			exports: {
				exports: 'Exportaciones',
				tooltip:
					'Puedes exportar imágenes/vídeos en calidad Estándar o Alta: <br> &#x2022; Estándar: Renderizado de calidad predeterminada. <br> &#x2022; Alta calidad: Ray-tracing mejorado, sombras y nitidez para imágenes/vídeos más detallados y profesionales.',
				standard: 'Imágenes y vídeos estándar',
				hq_images: 'Imágenes de alta calidad (HQ)',
				hq_videos: 'Vídeos de alta calidad (HQ)',
				rendering_speed: 'Velocidad de renderizado (para imágenes)',
				resolution_4k_export: 'Exportaciones en resolución 4K',
				file_3d_export: 'Exportaciones de archivos 3D (GLTF/GLB)',
			},
			ai_style_transfer: {
				ai_style_transfer: 'Transferencia de estilo IA',
				tooltip: 'Transforma instantáneamente tus iconos a diferentes estilos como Mate, Brillante, Tela, Vidrio, Inflado y más con Pixcap IA.',
				ai_style_transfer_usage: 'Usos de transferencia de estilo IA',
				custom_ai_prompt: 'Prompt de IA personalizado',
				bg_removal: 'Eliminador de fondo',
			},
			features: {
				features: 'Características',
				all_premiums: 'Todos los gráficos 3D premium (plantillas, iconos y escenas)',
				free_only: 'Solo gratis',
				graphics_upload: 'Carga de gráficos',
				fonts_upload: 'Carga de fuentes',
				custom_artboard_sizes: 'Tamaños de mesa de trabajo personalizados',
				resolution_4k_export: 'Exportaciones en resolución 4K',
				file_3d_export: 'Exportaciones de archivos 3D (GLTF/GLB)',
			},
			credit_based_features: {
				credit_based_features: 'Características basadas en créditos',
				super_download: 'Super Descarga',
				super_download_tooltip:
					'Super Descarga proporciona ray-tracing de alta calidad, <br> sombras y nitidez <br> para imágenes/videos detallados y profesionales. <br> &#x2022; 1 crédito por imagen <br> &#x2022; 1 crédito por 0.1 segundos de video',
				rendering_speed: 'Velocidad de renderizado (para imagen)',
				pixcap_ai: 'Pixcap IA',
				pixcap_ai_tooltip: '1 crédito por generación de IA',
				custom_ai_prompt: 'Indicación de IA personalizada',
				private_ai_output: 'Resultados de IA privados',
				background_remover: 'Eliminador de fondos',
				monthly_credits: 'créditos mensuales',
				super_and_ai: '(Descargas Super y Pixcap AI)',
				unlimited: 'Ilimitado',
				medium: 'Medio',
				fast: 'Rápido (3x)',
			},
			licensing: {
				licensing: 'Licencias',
				personal_use: 'Uso personal',
				commercial_use: 'Uso comercial',
				watermark: 'Marca de agua',
			},
			tailored_services: {
				tailored_services: 'Servicios a medida',
				modeling_services: 'Servicios de modelado 3D',
				custom_animations: 'Animaciones personalizadas',
				custom_mockups: 'Maquetas personalizadas',
				dedicated_support: 'Soporte dedicado',
			},
			support: 'Soporte',
			faq: {
				pricing: 'Precios',
				faqs: 'FAQs',
				what_is_hq_download: '¿Qué es la descarga de alta calidad?',
				what_is_hq_download_answer:
					'Las imágenes y vídeos exportados tendrán una calidad mucho más alta, con un trazado de rayos mejorado y sombras e iluminación más realistas.',
				what_is_super_download: '¿Qué es Super Descarga?',
				what_is_super_download_answer:
					'Las imágenes y videos exportados en formato Super tendrán una calidad mucho mayor, con un trazado de rayos mejorado y sombras e iluminación más realistas.',
				can_i_use_pixcap_for_free: '¿Puedo usar Pixcap gratis?',
				can_i_use_pixcap_for_free_answer:
					'¡Ciertamente! Pixcap ofrece un plan gratuito para todos. Puedes navegar por todas las plantillas y contenidos sin costo alguno. Si te gusta un activo o plantilla PRO, quieres mejorar tu juego de IA o usar la herramienta de maquetas animadas, puedes actualizar tu plan a Pro o Elite según tus necesidades. Con los planes de pago, puedes usar la súper descarga, la descarga 4K, descargar archivos GLB, acceder a todo el contenido y experimentar con maquetas 3D animadas.',
				will_my_subscription_automatically_renew: '¿Mi suscripción se renovará automáticamente?',
				will_my_subscription_automatically_renew_answer:
					'Sí, dependiendo del plan que hayas elegido, mensual o anual, tu suscripción se renovará automáticamente hasta que la canceles manualmente en cualquier momento. Seguirás teniendo acceso a los beneficios de tu plan hasta el siguiente ciclo de facturación.',
				what_are_pixcap_credits: '¿Qué son los Créditos Pixcap?',
				what_are_pixcap_credits_answer: `Los Créditos Pixcap te permiten experimentar con todo el potencial de Pixcap. Básicamente, necesitas créditos para Super Descarga, Pixcap AI y exportaciones de animación de Super Descarga, y cada herramienta usa una cantidad específica de créditos. Por ejemplo, 1 exportación de imagen estática usando súper renderizado vale 1 crédito, mientras que 1 segundo de exportación de animación de súper renderizado son {amount} créditos. Puedes aprender más sobre nuestros créditos en Créditos Pixcap.`,
				how_do_my_pixcap_credits_renew: '¿Cómo se renuevan mis Créditos Pixcap?',
				how_do_my_pixcap_credits_renew_answer:
					'Tus Créditos Pixcap se reinician mensualmente el 1 de cada mes. Cada crédito no utilizado del mes anterior se perderá, ¡así que asegúrate de usarlos todos!',
				do_free_users_also_have_pixcap_credits: '¿Los usuarios gratuitos también tienen Créditos Pixcap?',
				do_free_users_also_have_pixcap_credits_answer:
					'Sí, cada usuario gratuito tiene 30 Créditos Pixcap gratuitos mensuales. En caso de que necesites más créditos pero no quieras comprometerte con una suscripción, puedes comprar uno de nuestros Paquetes de Recarga.',
				can_pro_or_elite_users_also_buy_from_the_top_up_bundles: '¿Los usuarios Pro o Elite también pueden comprar de los Paquetes de Recarga?',
				can_pro_or_elite_users_also_buy_from_the_top_up_bundles_answer:
					'¡Sí! Si has usado todos tus créditos de suscripción, puedes comprar de nuestros Paquetes de Recarga - no tienes un límite mensual, ¡así que puedes comprar tantos como necesites!',
				can_i_use_pixcap_for_commercial_purposes: '¿Puedo usar Pixcap con fines comerciales?',
				can_i_use_pixcap_for_commercial_purposes_answer:
					'Todo el contenido exportado puede ser utilizado comercialmente, con atribución requerida para usuarios GRATUITOS. Los usuarios PRO y ELITE no tienen que proporcionar atribución y pueden usar todo el contenido de Pixcap comercialmente en sus proyectos.',
				will_i_lose_access_to_my_downloads_if_i_cancel_my_subscription: '¿Perderé acceso a mis descargas si cancelo mi suscripción?',
				will_i_lose_access_to_my_downloads_if_i_cancel_my_subscription_answer:
					'Tus descargas estarán con tu cuenta para siempre, independientemente de la suscripción.',
				what_is_your_refund_policy: '¿Cuál es su política de reembolso?',
				what_is_your_refund_policy_answer:
					'Los pagos por suscripciones de Pixcap no son reembolsables a menos que vivas en la U.E. o Turquía, en cuyo caso puedes ser elegible para un reembolso solo si cancelas tu suscripción dentro de los 14 días posteriores a la compra y <span class="text-weight-700">no has llegado a usar el servicio, es decir, no has descargado ningún recurso de Pixcap.</span>' +
					'<div class="mt-m">Para solicitar un reembolso, por favor envíanos un correo electrónico a <span class="text-color-primary-5 text-underline">support@pixcap.com</span> e incluye tu país de residencia.</div>',
			},
			testimonials: {
				testimonials: 'Testimonios',
				header: 'Lo que dicen nuestros clientes sobre nosotros',
			},
			do_more: {
				do_more: 'Haz más',
				header: 'Actualiza y explora una suite completa de potentes herramientas de diseño 3D y AI',
				text: 'Pixcap es una plataforma basada en navegador que ofrece iconos 3D personalizables, gráficos e ilustraciones. Muchas de nuestras características son gratuitas, brindándote una solución integral para todas tus necesidades de edición 3D. La creación de una cuenta no es obligatoria pero se recomienda ya que te permite almacenar tu trabajo creativo para que puedas editar y descargar tus iconos en cualquier momento.',
				upgrade_now: 'Actualizar ahora',
			},
		},
		user_settings: {
			account_settings: {
				header: 'Configuración de la cuenta',
				text: 'Ver y actualizar los detalles de tu cuenta, perfil y más.',
				profile_photo: 'Foto de perfil',
				account_settings: 'Configuración de la cuenta',
				display_name: 'Nombre para mostrar',
				bio: 'Biografía',
				bio_placeholder: 'Preséntate',
				email_address: 'Dirección de correo electrónico',
				change_photo: 'Cambiar foto',
				save_photo: 'Guardar imagen',
			},
			invite_friends: {
				invite_friends: 'Invitar amigos',
				header: 'Invita a amigos, obtén recompensas al instante',
				text: 'Comparte el enlace de invitación con tus amigos y rastrea tus recompensas obtenidas.',
				invite: 'Invitar',
				by_link: 'Por enlace',
				copy: 'Copiar enlace',
				by_email: 'Por correo electrónico',
				enter_email: 'Ingresa dirección de correo electrónico',
				send: 'Enviar correo electrónico',
				how_it_works: 'Cómo funciona',
				note_text: 'Nota:',
				note: 'El Programa de Referidos de Pixcap ha terminado. La recompensa obtenida a continuación solo es relevante para aquellos que participaron en el programa antes de su cierre.',
				earned_rewards: 'Recompensas obtenidas',
				referrals: 'Referidos',
				date: 'Fecha',
				status: 'Estado',
				earnings: 'Ganancias',
				no_rewards: 'No se han obtenido recompensas.',
				invitation_sent: '¡Invitación enviada!',
				tooltip: {
					pending: `Esperando que tu amigo se <br> registre desde {start_date}-{expired_date}.`,
					joined: 'Esperando que tu amigo <br> descargue al menos 01 diseño.',
				},
				claimed: 'Reclamado',
				pending: 'Pendiente',
			},
			subscription: {
				renew_plan: 'Renovar Plan',
				text: 'Ver, gestionar o cancelar tus suscripciones.',
				free_text: 'Este plan viene con recursos y características limitadas.',
				free_text_2: 'Actualiza para obtener acceso ilimitado a más de 15.000 recursos premium y descargas de alta calidad.',
				billed_annually: 'facturado anualmente',
				per_month: 'por mes',
				per_day: 'por día',
				downloads: 'Descargas',
				downloads_limit: {
					default: 'Imagen + video de calidad predeterminada',
					hq_image: 'Imagen de alta calidad',
					hq_video: 'Video de alta calidad',
				},
				ai_style: 'Transferencia de estilo IA',
				ai_limits: {
					ai_uses: 'Usos de IA',
				},
				subscription: 'Suscripción',
				topped_up: 'Recargado',
				plan: '',
				on_free: 'Ahora tienes una cuenta GRATUITA',
				sub_expired: '**Tu suscripción ha expirado.',
				payment_unsucess: '**Tu pago no tuvo éxito, por favor intenta suscribirte de nuevo.',
				payment_pending: '**Tu pago está pendiente, por favor verifica para continuar con el pago.',
				payment_invalid: '**Tu pago es inválido, por favor verifica y actualiza tus detalles de pago.',
				plan_to_renew: '**Por favor elige un plan para renovar.',
				check_payment: 'Verificar pago',
				go_to_payment: 'Ir al pago',
				choose_a_plan: 'Elegir un plan',
				complete_payment_in_your_plan: 'Completa el pago para continuar con tu {plan}',
				cancel_plan: 'Cancelar Plan',
				change_plan: 'Cambiar Plan',
				plan_end_on: 'El plan termina el',
				next_payment: 'Próximo pago',
				view_all_invoice: 'Ver todas las facturas',
				credits: 'Créditos',
				current_monthly_credits: 'Saldo actual de créditos mensuales de suscripción',
				credit_note: 'Estos créditos son parte de tu suscripción y se reinician mensualmente.',
				topup_balance: 'Saldo de recarga',
				topup: 'Recargar créditos',
				note: 'Créditos que obtuviste a través de recargas. Estos créditos no <br />tendrán un límite mensual y no se gastarán mientras <br />tus créditos de suscripción estén disponibles.',
				per_year: 'Por año',
				for_3_months: 'Por 3 meses',
				get_more_create_more: '¡Obtén más, crea más!',
				save_more: 'Ahorra más con el plan Anual',
				upgrade_plan: 'Mejorar Plan',
				unlock_all: 'Desbloquear Todas las Funciones',
				topup_modal: {
					choose_a_bundle: 'Elige un paquete',
					credits: 'Créditos',
					text: 'Equivalente a <strong>1 de 3</strong> ofertas a continuación:',
					super_render_images: 'Imágenes Super Render',
					super_render_videos: 'Videos Super Render',
					images: '{credits} imágenes',
					seconds: '{credits} segundos',
					ai: 'Generaciones de IA',
					generations: '{credits} generaciones',
					amount: 'Cantidad',
					purchase_now: 'Comprar ahora',
				},
				cancel_sub_modal: {
					cancel_sub: 'Cancelar suscripción',
					sub: 'Perderás todas las funciones premium una vez que tu plan {plan} expire el <strong>{expireDay}</strong>',
					sub_alt: 'Perderás inmediatamente todas las funciones premium una vez que canceles.',
					let_us_know: 'Cuéntanos por qué te vas',
					write_feedback: 'Escribe algunos comentarios...',
					confirmed: '¡Tu cancelación está confirmada!',
					update_text: 'La suscripción puede tardar unos momentos en actualizarse.',
					enjoy:
						'Aún puedes disfrutar de todas las funciones de Pixcap {plan} hasta el <strong>{expireDay}</strong>. Después de eso, ya no tendrás acceso a ninguna función premium.',
					change_your_mind: 'Si cambias de opinión, considera renovar tu plan en la Configuración de Suscripción 😊!',
					sad: '¡Nos entristece verte partir! Aún puedes disfrutar de Pixcap como usuario <strong>GRATUITO</strong>.',
					change_your_mind_2: 'Si cambias de opinión, considera suscribirte de nuevo en la Configuración de Suscripción 😊!',
					keep: 'Mantener Pixcap {name}',
					got_it: 'Entendido',
					reason: {
						reason_1: 'Es demasiado caro',
						reason_2: 'Necesito más funciones',
						reason_3: 'Encontré otro sitio web similar',
						reason_4: 'No lo uso con tanta frecuencia',
						reason_5: 'No hay muchos recursos de calidad',
						reason_6: 'No creo que Pixcap {plan} valga la pena',
						reason_7: 'Encontré otro sitio web similar',
					},
				},
				plan_name: {
					pro_quarterly_plan: 'Plan Pro Trimestral',
					elite_quarterly_plan: 'Plan Élite Trimestral',
					free_plan: 'Plan GRATUITO',
					pro_annual_plan: 'Plan Pro Anual',
					elite_annual_plan: 'Plan Élite Anual',
					pro_monthly_plan: 'Plan Pro Mensual',
					elite_monthly_plan: 'Plan Élite Mensual',
				},
			},
			promotion: {
				promotion: 'Promociones',
				text: 'Rastrea descuentos de referidos y regalos semanales.',
				only_one:
					'Solo <span class="text-weight-600">01</span> recompensa basada en suscripción por facturación. Encuentra tu favorita a continuación. 🌟',
				got_it: 'Entendido',
				no_promotions: 'No hay promociones disponibles',
				watermark_promo: {
					title: 'Regalos por referidos',
					description: 'Descargas ilimitadas sin marca de agua en activos gratuitos',
					valid_until: 'Válido hasta {date}',
					no_expiration_date: 'Sin fecha de expiración',
					unlimited_referrals: 'Referidos ilimitados',
					learn_more: 'Saber más',
					claim: 'Reclamar',
					claimed: 'Reclamado',
				},
			},
			change_password: {
				change_password: 'Cambiar Contraseña',
				current: 'Contraseña actual',
				current_placeholder: 'Ingresa la contraseña actual',
				new: 'Nueva contraseña',
				new_placeholder: 'Ingresa la nueva contraseña',
				confirm: 'Confirmación de contraseña',
				confirm_placeholder: 'Vuelve a escribir la nueva contraseña',
				error_1: 'La contraseña no puede estar vacía',
				error_2: 'La nueva contraseña no puede estar vacía',
				error_3: 'La confirmación de la contraseña no puede estar vacía',
				error_4: 'Las contraseñas no coinciden',
				error_5: 'Contraseña incorrecta',
			},
			email_notification: {
				text: 'Configura tus preferencias para boletines y alertas de actividad de la cuenta.',
				email_notification: 'Notificaciones por Correo Electrónico',
				alerts_and_newsletter: 'Alertas y Boletín',
				downloads: 'Descargas',
				downloads_text: 'Recibe notificaciones cuando las imágenes de alta calidad y de IA estén disponibles para descargar',
				events: 'Eventos',
				events_text: 'Recibe notificaciones cuando se publiquen tus paquetes de eventos preferidos',
				newsletters: 'Boletines',
				newsletters_text: 'Recibe boletines de marketing con noticias e inspiración de diseño',
				account_activity: 'Actividad de la cuenta',
				email_when: 'Envíame un correo electrónico cuando',
				comments: 'Alguien comenta en mi proyecto',
				mentions: 'Alguien me menciona',
				collab_invites: 'Recibo invitaciones para colaborar en sus proyectos',
				pending_actions: 'Acciones pendientes',
				conversation: 'Conversación en proyectos',
			},
			save: 'Guardar',
			save_changes: 'Guardar cambios',
		},
		shared_project: {
			search_placeholder: 'Buscar proyecto compartido...',
			title: 'Compartido conmigo',
		},
		contributor_profile: {
			pixcap: {
				display_name: 'Equipo Pixcap',
				description:
					'Obtén acceso a más de 15,000 recursos 3D personalizables diseñados para UX/UI, tecnología, viajes, negocios, educación, alimentación, deportes y mucho más. Puedes cambiar colores, rotar, posar y añadir efectos para hacer que los iconos, ilustraciones y personajes sean verdaderamente tuyos.',
			},
		},
	},
	payments: {
		method: 'Métodos de Pago',
		card_ewallets: 'Tarjetas / Billeteras electrónicas',
	},
	common_elements: {
		explore_item_type_banner: {
			icons: {
				title: 'Personaliza íconos 3D e íconos animados',
				description:
					'Prueba el Editor 3D para ajustar los colores, los materiales y los estilos de animación de tus íconos 3D. Descarga íconos 3D gratuitos en PNG, GLB, GIF, MP4 para usar en Figma, Sketch, Adobe y más.',
				btn_text: 'Explorar íconos 3D',
			},
			characters: {
				title: 'Da vida a los personajes 3D',
				description:
					'Descubre la única herramienta de posicionamiento 3D que te permite posar tus personajes 3D, modificar los colores y más, sin necesidad de experiencia en modelado 3D.',
				btn_text: 'Explorar personajes 3D',
			},
			mockups: {
				title: 'Crea maquetas 3D animadas en segundos',
				description:
					'Las maquetas ahora son totalmente personalizables: carga tu etiqueta en cualquier maqueta animada y descárgala en 4K en menos de 5 minutos.',
				btn_text: 'Explorar maquetas',
			},
		},
		view_all: 'Ver todo',
		coming_soon: 'Próximamente',
		status: {
			all: 'Todos los estados',
			completed: 'Completado',
			processing: 'Procesando',
			failed: 'Fallido',
			cancelled: 'Cancelado',
			preparing: 'Preparando',
			removed: 'Eliminado',
		},
		packs_count: '{count} paquetes',
		items_count: '{count} elementos',
		filter: {
			filters: 'Filtros',
			type: {
				type: 'Tipo',
				all: 'Todos',
				animated_static: 'Animado + Estático',
				animated: 'Animado',
				static: 'Estático',
			},
			price: {
				price: 'Precio',
				all: 'Todos',
				premium: 'Premium',
				free_paid: 'Gratis + De pago',
				free_premium: 'Gratis + Premium',
				free: 'Gratis',
				paid: 'De pago',
			},
			size: {
				size: 'Tamaño',
				all: 'Todos los tamaños',
			},
			pack_or_item: {
				view_as: 'Ver como',
				items: 'Artículos',
				packs: 'Paquetes',
			},
		},
		old: 'Antiguo',
		home: 'Inicio',
		assets: 'Recursos 3D',
		explore: 'Explorar',
		edit: 'Editar',
		high_quality: 'Alta calidad',
		default: 'Predeterminado',
		subscribe_and_edit: 'Suscribir y Editar',
		all_assets: 'Todos los activos',
		icons: 'Íconos 3D',
		characters: 'Personajes 3D',
		mockups: 'Maquetas',
		asset_packs: 'Paquetes de Assets 3D',
		icon_packs: 'Paquetes de Íconos 3D',
		character_packs: 'Paquetes de Personajes 3D',
		mockup_pack: 'Paquetes de Maquetas',
		new: 'Nuevo',
		by: 'por',
		premium: 'Premium',
		unlock_all_access: 'Desbloquear todo el acceso',
		discount_text: 'Ahorre {number}%',
		get_elite_for_unlimited_access: 'Obtén Elite para acceso ilimitado',
		upgrade_to_elite: 'Actualiza a Elite',
		explore_now: 'Explorar ahora',
		get_started: 'Comenzar',
		copy_link: 'Copiar enlace',
		link_copied: 'Enlace copiado',
		more: 'más',
		open_in_new_tab: 'Abrir en nueva pestaña',
		or: 'o',
		show_all: 'Mostrar todo',
		view_more: 'Ver más',
		free: 'Gratis',
		purchased: 'Comprado',
		animated: 'Animado',
		editable: 'Editable',
		tags: {
			all: 'Todos',
			trending: 'Tendencia',
		},

		edit_in_3d: 'Editar en 3D',
		view_details: 'Ver detalles',
		read_more: 'Leer más',
		download: 'Descargar',
		open: 'Abrir',
		delete_modal: {
			title: 'Eliminar {entity}',
			message: '¿Estás seguro de que quieres eliminar este {entity}?',
			delete: 'Eliminar',
		},
		empty: {
			with_search: {
				message: 'No se encontraron resultados',
				sub_message: "¿Quieres que diseñemos '{search}' {type} para ti? Envíanos un",
			},
			request: 'Solicitar',
			message: '¡Ups! Este lugar está vacío por ahora.',
			sub_message: 'Intenta ajustar tus filtros, o vuelve pronto para ver actualizaciones',
		},
		cancel: 'Cancelar',
		error_404: 'Lo sentimos, no pudimos encontrar la página que estás buscando.',
		error_500: 'Algo salió mal, por favor intenta de nuevo más tarde.',
		under_maintain: 'Actualmente estamos realizando un mantenimiento programado \n y volveremos a estar en línea pronto, gracias por tu paciencia.',
		error_password_length: 'La contraseña requiere al menos 8 caracteres o números (sin espacios).',
		error_password_not_matched: 'La confirmación de la contraseña no coincide.',
		back_to_explore: 'Volver a Explorar',
		retry: 'Reintentar',
		render_type: {
			cloud: 'Renderizado en la nube',
			default: 'Renderizado predeterminado',
			ai: 'Estilo IA',
		},
		remove: 'Eliminar',
	},
	toast: {
		error: 'Algo salió mal. Por favor, actualice e inténtelo de nuevo.',
		success_delete_project: `El proyecto {name} se eliminó correctamente`,
	},
	tag_name: {
		branding: 'Marca',
		brand_kit: 'kit de marca',
		device: 'dispositivo',
		business: 'empresa',
		casual: 'casual',
		ecommerce: 'comercio electrónico',
		essential: 'esencial',
		fintech: 'fintech',
		healthcare: 'sanidad',
		laptop: 'ordenador portátil',
		profession: 'profesión',
		smartphone: 'smartphone',
		animated: 'Animado',
		laptops: 'Portátiles',
		phones: 'Teléfonos',
		tablets: 'Tabletas',
		tablet: 'Tableta',
		desktops: 'Ordenadores de escritorio',
	},
	seo_meta: {
		customizable: 'Personalizable',
		free: 'Gratis',
		animated: 'Animado',
		text_3d_animated_icons: 'Iconos 3D animados',
		text_3d_icons_illus: 'Iconos e ilustraciones 3D',
		text_animated_icons: 'iconos animados',
		text_icons_logos_symbols: 'iconos, logotipos, símbolos',
		title_item_detail: `Descarga {keyWord} en PNG, GLB, GIF, MP4 - Pixcap`,
		description_item_illustration_detail: `{keyWord} personalizable para proyectos de diseño ✓ Más de 100 ilustraciones 3D relacionadas ✓ Alta resolución ✓ Uso comercial gratuito`,
		description_item_character_detail: `{keyWord} personalizable para proyectos de diseño ✓ Más de 100 personajes 3D relacionados ✓ Alta resolución ✓ Uso comercial gratuito`,
		description_item_icon_detail: `{keyWord} personalizable para proyectos de diseño ✓ Más de 100 iconos 3D relacionados ✓ Alta resolución ✓ Uso comercial gratuito`,
		description_item_mockup_detail: `{keyWord} personalizable para proyectos de diseño ✓ Más de 100 maquetas 3D relacionadas ✓ Alta resolución ✓ Uso comercial gratuito`,
		description_item_design_detail: `{keyWord} personalizable para proyectos de diseño ✓ Más de 100 diseños 3D relacionados ✓ Alta resolución ✓ Uso comercial gratuito`,
		title_brandkit_detail: `Plantillas de maquetas de marca para tu negocio - Pixcap`,
		description_brandkit_detail: `Crea una impresionante identidad de marca en línea con nuestras plantillas de maquetas de marca gratuitas y premium. Visualiza tu diseño en logo, papelería, dispositivos, material de oficina y más.`,
		title_pack_detail: `Paquete de iconos de {packKeyWord} | Descarga {packSize} iconos e ilustraciones 3D`,
		description_pack_detail: `Paquete de iconos 3D de {packKeyWord} personalizable para proyectos de diseño ✓ Más de 100 paquetes de iconos relacionados ✓ Alta resolución ✓ Gratis para uso comercial`,

		title_library_icons: 'Paquetes de iconos 3D {currentPlanQuery}{currentTagQuery}{currentTypeQuery} - Descarga gratuita PNG, GLB, GIF, MP4',
		description_library_icons: `Encuentra paquetes de iconos 3D {currentPlanQuery}{currentTagQuery}{currentTypeQuery} para diseño gráfico, UX/UI, presentaciones y más ✓ Personaliza, descarga y comparte ✓ Alta resolución ✓ Gratis para uso comercial`,
		title_library_characters: `Personajes 3D {currentPlanQuery}{currentTagQuery}{currentTypeQuery} - Descarga gratuita PNG, GLB, GIF, MP4`,
		description_library_characters: `Encuentra personajes 3D {currentPlanQuery}{currentTagQuery}{currentTypeQuery} para diseño gráfico, UX/UI, presentaciones y más ✓ Personaliza, descarga y comparte ✓ Alta resolución ✓ Gratis para uso comercial`,
		title_library_illusions: `Ilustraciones 3D {currentPlanQuery}{currentTagQuery}{currentTypeQuery} - Descarga gratuita PNG, GLB, GIF, MP4`,
		description_library_illusions: `Encuentra ilustraciones 3D {currentPlanQuery}{currentTagQuery}{currentTypeQuery} para diseño gráfico, UX/UI, presentaciones y más ✓ Personaliza, descarga y comparte ✓ Alta resolución ✓ Gratis para uso comercial`,
		title_library_mockups: `Maquetas 3D {currentPlanQuery}{currentTagQuery}{currentTypeQuery} - Descarga gratuita PNG, GLB, GIF, MP4`,
		description_library_mockups: `Encuentra maquetas 3D {currentPlanQuery}{currentTagQuery}{currentTypeQuery} para UX/UI, portafolio, presentaciones y más ✓ Personaliza, descarga y comparte ✓ Alta resolución ✓ Gratis para uso comercial`,
		title_library_mockups_default: `Crea maquetas 3D y maquetas 3D animadas en segundos`,
		description_library_mockups_default: `Genera imágenes y videos de maquetas 3D realistas para tus productos, pósters, tarjetas de visita, portadas de libros y más. Generador de maquetas gratuito para usar en línea.`,
		title_library_designs: 'Iconos 3D, ilustraciones, maquetas - Descarga gratuita PNG, GLB, GIF, MP4',
		description_library_designs:
			'Explora y descarga más de 10,000 elementos 3D para diseño gráfico, presentaciones y proyectos web ✓ Alta resolución ✓ Gratis para uso comercial.',

		title_search_prefix: ' - Descarga gratuita PNG, GLB, GIF, MP4',
		title_search_pack_list: `{totalItems} paquetes de iconos {currentPlanQuery}{searchWord} {currentTypeQuery} - Descarga gratuita PNG, GLB, GIF, MP4`,
		description_search_pack_list: `Personaliza y descarga paquetes de iconos 3D {currentPlanQuery}{searchWord} {currentTypeQuery} ✓ Alta resolución ✓ Gratis para uso comercial ✓ Nuevos paquetes agregados diariamente.`,
		title_search_icon_list: `{totalItems} {currentPlanQuery}{searchWord} {currentTypeQuery} - Descarga gratuita PNG, GLB, GIF, MP4`,
		description_search_icon_list: `Personaliza y descarga {currentPlanQuery}{searchWord} {currentTypeQuery} 3D ✓ Alta resolución ✓ Gratis para uso comercial ✓ Nuevos paquetes agregados diariamente.`,
		title_search_scene_list: `{totalItems} ilustraciones 3D {currentPlanQuery}{searchWord} {currentTypeQuery} - Descarga gratuita PNG, GLB, GIF, MP4`,
		description_search_scene_list: `Personaliza y descarga ilustraciones 3D {currentPlanQuery}{searchWord} {currentTypeQuery} ✓ Alta resolución ✓ Gratis para uso comercial ✓ Nuevas ilustraciones agregadas diariamente.`,
		title_search_mockup_list: `{totalItems} maquetas 3D de {searchWord} - Descarga gratuita PNG, GLB, GIF, MP4`,
		description_search_mockup_list: `Personaliza y descarga maquetas 3D de {searchWord} ✓ Alta resolución ✓ Gratis para uso comercial ✓ Nuevas maquetas 3D agregadas diariamente.`,
		title_search_template_list: `{totalItems} diseños 3D de {searchWord} - Descarga gratuita PNG, GLB, GIF, MP4`,
		description_search_template_list: `Personaliza y descarga diseños 3D de {searchWord} ✓ Alta resolución ✓ Gratis para uso comercial ✓ Nuevos diseños 3D agregados diariamente.`,
		contributor_page: {
			title: 'Portafolio de Diseñador 3D de {name} | Pixcap',
			description:
				'Explora y descarga recursos de diseño 3D de alta calidad creados por {name}. Descubre la comunidad de diseñadores de Pixcap y más hermosos recursos 3D ahora.',
		},
	},
};

export default es;
