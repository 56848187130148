import en from '@/lang/en';
import es from '@/lang/es';
import de from '@/lang/de';
import br from '@/lang/br';
import kr from '@/lang/kr';
import jp from '@/lang/jp';

export default {
	en,
	es,
	de,
	br,
	kr,
	jp,
};
